.resume{
  background: white;
  color: black;

  iframe {
    display: block;       /* iframes are inline by default */
    height: calc(100vh - 300px);        /* Viewport-relative units */
    width: 100%;
  }

  a:link {
    color: white !important;
  }

  h5{
    font-weight: 700;
  }
}
