.options{
  background: white;
  color: black !important;

  img {
    max-height: inherit;
    border-radius: 10px;
    margin-top: 0
  }

  .typing{
    color: green;
  }

  .card-size{
    background: white;
    border: transparent;
  }

  .card-title{
    color: black;
    font-weight: bold;
  }
  .accordion-button{
    background: whitesmoke;
    color: black !important;
    font-weight: bold;  
  }
  .accordion-header-tag{
    width: 100%;
    text-align: center  
  }
}