.footer{
  position:fixed;
  bottom:0;
  background: white;
  height: 20px;
  width: 100vw;

  span{
    color: black !important;
    // font-weight: bold;
  }
  a{
    color: black !important;
  }
}