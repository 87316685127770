.experience{
  background: white;
  color: black;

  img {
    max-height: inherit;
    border-radius: 10px;
    margin-top: 0;
  }

  h5{
    font-weight: 700;
    z-index: 999;
  }
}
