.experienceListItem{
  background: inherit;
  color: black;

  .card-header{
    font-weight: 700;
  }

  .card-size{
    width: 100%;
  }
}